.primary-text {
  color: #858585;
}

.secondary-text {
  color: #b8b8b8;
  font-size: 14px;
  line-height: 18px;
}

.observation {
  padding-top: 10px;
  border-top: 1px solid #b8b8b8;
}

.schedule .primary-text:last-child,
.schedule .secondary-text:last-child {
  padding-bottom: 8px;
}

.exception {
  font-size: 14px;
}

.regions {
  max-height: 400px;
}

.regions .exception:first-child {
  border-top: 1px solid #b8b8b8;
}