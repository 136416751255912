@font-face {
  font-family: Gilmer;
  font-size: 14px;
  src: url(/static/media/Gilmer_Regular.f779e3a4.otf)
}
@font-face {
  font-family: Gilmer-Bold;
  font-size: 14px;
  src: url(/static/media/Gilmer_Bold.fc515556.otf)
}
@font-face {
  font-family: Gilmer-Light;
  font-size: 14px;
  src: url(/static/media/Gilmer_Light.47186cc9.otf)
}
@font-face {
  font-family: Gotham;
  font-size: 14px;
  src: url(/static/media/Gotham_Medium.ba7ee4ff.woff)
}
@font-face {
  font-family: Gotham-Bold;
  font-size: 14px;
  src: url(/static/media/Gotham_Bold.b51ec430.woff)
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gilmer', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-ladda-primary {
  border-radius: 8px;
  border: 0;
  background-color: #00BF91 !important;
  width: 100%;
  height: 40px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Gilmer-Light !important;
  font-size: 12px !important;
  margin-bottom: 10px;
  outline: none;
  padding: 0px 25px 0px 25px;
  transition: background-color .5s;
}

.btn-ladda-primary:disabled {
  background-color: #00a881 !important;
  cursor: not-allowed;
}

.btn-ladda-primary:hover {
  background-color: #00a881 !important;
}

.btn-small {
  width: 180px;
}

.pretty-link {
  color: #828282;
  text-decoration: underline;
}

.pretty-link:hover {
  text-decoration: none;
}

.lazy-load-image-loaded {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.tracking-in-contract-bck-top {
	-webkit-animation: tracking-in-contract-bck-top 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-top 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.tracking-in-contract-bck-bottom {
	-webkit-animation: tracking-in-contract-bck-bottom 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.scale-down-ver-top {
	-webkit-animation: scale-down-ver-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-ver-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}


.slick-slider, .slick-slider * {
  height: 100%;
}
.link {
  text-decoration: none;
  color: #ccc;
}
.link.active {
  color: #fff;
  font-weight: 700;
}
h1 {
  font-family: 'Gotham-Bold';
  font-size: 1.6em;
  line-height: 1.2em;
}

h4 {
  font-size: .9em;
  color: #ccc;
  line-height: 2em;
}
.developed-by {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #707070;
  text-align: left;
}
.developed-by {
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
  color: #707070;
  text-align: left;
}
.pretty-select option {
  width: 99%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pretty-select option:hover {
  cursor: pointer;
  background-color: #ccc;
}

.pretty-select-open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.pretty-select-close {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.pretty-select::-webkit-scrollbar {
  width: 10px;
}

.pretty-select::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.pretty-select::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.pretty-select::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/*.pretty-select-container {
  position: relative;
}

.pretty-select-container select {
  display: none;
}

.pretty-select-selected {
  background-color: #fcfcfc;
}

.pretty-select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #A8A8A8 transparent transparent transparent;
}

.pretty-select-selected.pretty-select-arrow-active:after {
  border-color: transparent transparent #A8A8A8 transparent;
  top: 7px;
}

.pretty-select-items div, .pretty-select-selected {
  color: #A8A8A8;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.pretty-select-items {
  position: absolute;
  background-color: #fcfcfc;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}*/

.marker-a {
  fill:#707070;
}
.marker-b {
  fill:none;
  stroke:#707070;
  stroke-width:5px;
}
.primary-text {
  color: #858585;
}

.secondary-text {
  color: #b8b8b8;
  font-size: 14px;
  line-height: 18px;
}

.observation {
  padding-top: 10px;
  border-top: 1px solid #b8b8b8;
}

.schedule .primary-text:last-child,
.schedule .secondary-text:last-child {
  padding-bottom: 8px;
}

.exception {
  font-size: 14px;
}

.regions {
  max-height: 400px;
}

.regions .exception:first-child {
  border-top: 1px solid #b8b8b8;
}
.pulsating-circle {
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
  margin: auto;
  margin-top: 15px;
}
.pulsating-circle:before {
  content: '';
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
          animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
.pulsating-circle-green:before {
  background-color: #00ffc3;
}
.pulsating-circle-green:after {
  background-color: #00bf91;
}
.pulsating-circle-red:before {
  background-color: #e67e22;
}
.pulsating-circle-red:after {
  background-color: #d35400;
}

.pulsating-mobile {
  width: 15px;
  height: 15px;
  margin: 0;
  margin-top: 10px;
}

.pulsating-mobile:before {
  background-color: transparent;
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
  }
  80%, 100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
.products-cart-container {
  width: 88%;
  margin-top: 16px;
}

.breadcrumbs {
  color: #A8A8A8;
  padding: 8px;
}

.product-name {
  color: #707070;
  margin: 0;
}

.product-presentation {
  color: #a8a8aa;
  margin: 0;
}

.product-price {
  height: 50px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.filter-dropdown-open {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.filter-dropdown-close {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.filter-dropdown::-webkit-scrollbar {
  width: 10px;
}

.filter-dropdown::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.filter-dropdown::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.filter-dropdown::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
.product-modal {
  width: 80%;
  border-radius: 10px;
}
li {
  margin-bottom: 5px;
  color: #707070;
}
.primary-text {
  color: #858585;
}

.secondary-text {
  color: #b8b8b8;
}

.observation {
  padding-top: 10px;
  border-top: 1px solid #b8b8b8;
}

.schedule .primary-text:last-child,
.schedule .secondary-text:last-child {
  padding-bottom: 8px;
}

.exception {
  font-size: 14px;
}

.regions .exception:first-child {
  border-top: 1px solid #b8b8b8;
}
.products-cart-container {
  width: 88%;
  margin-top: 16px;
}

.breadcrumbs {
  color: #A8A8A8;
  padding: 8px;
}

.product-name {
  color: #707070;
  margin: 0;
}

.product-presentation {
  color: #a8a8aa;
  margin: 0;
}

.product-price {
  height: 50px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
