h1 {
  font-family: 'Gotham-Bold';
  font-size: 1.6em;
  line-height: 1.2em;
}

h4 {
  font-size: .9em;
  color: #ccc;
  line-height: 2em;
}