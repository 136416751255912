.pretty-select option {
  width: 99%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pretty-select option:hover {
  cursor: pointer;
  background-color: #ccc;
}

.pretty-select-open {
  transform: rotate(180deg);
  transition: transform 1s;
}

.pretty-select-close {
  transform: rotate(0deg);
  transition: transform 1s;
}

.pretty-select::-webkit-scrollbar {
  width: 10px;
}

.pretty-select::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.pretty-select::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.pretty-select::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/*.pretty-select-container {
  position: relative;
}

.pretty-select-container select {
  display: none;
}

.pretty-select-selected {
  background-color: #fcfcfc;
}

.pretty-select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #A8A8A8 transparent transparent transparent;
}

.pretty-select-selected.pretty-select-arrow-active:after {
  border-color: transparent transparent #A8A8A8 transparent;
  top: 7px;
}

.pretty-select-items div, .pretty-select-selected {
  color: #A8A8A8;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.pretty-select-items {
  position: absolute;
  background-color: #fcfcfc;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}*/
