.filter-dropdown-open {
  transform: rotate(-90deg);
  transition: transform 1s;
}

.filter-dropdown-close {
  transform: rotate(0deg);
  transition: transform 1s;
}
.filter-dropdown::-webkit-scrollbar {
  width: 10px;
}

.filter-dropdown::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.filter-dropdown::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.filter-dropdown::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}