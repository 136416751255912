.products-cart-container {
  width: 88%;
  margin-top: 16px;
}

.breadcrumbs {
  color: #A8A8A8;
  padding: 8px;
}

.product-name {
  color: #707070;
  margin: 0;
}

.product-presentation {
  color: #a8a8aa;
  margin: 0;
}

.product-price {
  height: 50px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}